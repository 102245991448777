import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Survey from './Survey';
import SurveyComplete from './SurveyComplete';
import SurveyError from './SurveyError';
import SurveyScreenOut from './SurveyScreenOut';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/complete" component={SurveyComplete} />
        <Route exact path="/error" component={SurveyError} />
        <Route exact path="/screenout" component={SurveyScreenOut} />
        <Route exact path="/survey" component={Survey} />
      </Switch>
    </Router>
  );
};

export default App;
