import { Box, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ImageIcon from '@material-ui/icons/Image';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import './Survey.css'

const SurveyAction = {
  Image: 'image',
};

const DisplayOption = {
  FullScreenSurvey: 'fullScreenSurvey',
  FullScreenImage: 'fullScreenImage',
  SplitScreen: 'splitScreen',
};

const styles = makeStyles((theme) => ({
  container: {},
  surveyIFrame: {
    position: 'relative',
    height: '100vh',
    width: '100%',
    borderWidth: 0,
  },
  imageScrollContainer: {
    maxHeight: '100vh',
    overflow: 'auto',
  },
  image: {
    maxWidth: '99%',
  },
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));

function Survey(props) {
  const theme = useTheme();
  const classes = styles(props);

  const [displayOption, setDisplayOption] = useState(DisplayOption.SplitScreen);
  const [imageUrl, setImageUrl] = useState(null);
  const [open, setOpen] = useState(false);

  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  // Pop the qualtrics URL
  const qualtricsUrl = queryParams.survey;
  delete queryParams.survey;

  // Generate the full qualtrics URL with dynata query params.
  const qualtricsQueryParams = qs.stringify(queryParams, { encode: false });
  const qualtricsUrlWithParams = `${qualtricsUrl}?${qualtricsQueryParams}`;

  let actions = [
    {
      id: DisplayOption.FullScreenSurvey,
      icon: <AssignmentIcon />,
      name: 'Full Screen Survey',
      onClick: () => handleSpeedDialAction(DisplayOption.FullScreenSurvey),
    },
    {
      id: DisplayOption.FullScreenImage,
      icon: <ImageIcon />,
      name: 'Full Screen Image',
      onClick: () => handleSpeedDialAction(DisplayOption.FullScreenImage),
    },
    {
      id: DisplayOption.SplitScreen,
      icon: <VerticalSplitIcon />,
      name: 'Split Survey and Image',
      onClick: () => handleSpeedDialAction(DisplayOption.SplitScreen),
    },
  ];

  // Filter out whatever the display option currently is.
  actions = actions.filter((action) => action.id !== displayOption);

  // For small screens, don't allow Split Screen.
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  if (isSmallScreen) {
    actions = actions.filter((action) => action.id !== DisplayOption.SplitScreen);

    if (displayOption === DisplayOption.SplitScreen) {
      setDisplayOption(DisplayOption.FullScreenSurvey);
    }
  }

  // If there is no image, don't allow to show image.
  if (!imageUrl) {
    actions = actions.filter(
      (action) => ![DisplayOption.FullScreenImage, DisplayOption.SplitScreen].includes(displayOption)
    );
  }

  useEffect(() => {
    const handler = (event) => {
      try {
        const action = event.data.action;
        if (action === SurveyAction.Image) {
          const url = event.data.url;

          setImageUrl(url);

          if (!url) {
            // If there is no image, change to full screen survey.
            setDisplayOption(DisplayOption.FullScreenSurvey);
          } else if (displayOption === DisplayOption.FullScreenSurvey) {
            // If display is not showing the image, change to split screen.
            setDisplayOption(DisplayOption.SplitScreen);
          }
        }
      } catch (err) {
        console.log('Unable to parse: ', event.data);
      }
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, [displayOption]);

  const getSurveyWidth = () => {
    if (displayOption === DisplayOption.FullScreenSurvey) {
      return 12;
    } else if (displayOption === DisplayOption.FullScreenImage) {
      return false;
    } else {
      return 6;
    }
  };

  const getImageWidth = () => {
    if (displayOption === DisplayOption.FullScreenSurvey) {
      return false;
    } else if (displayOption === DisplayOption.FullScreenImage) {
      return 12;
    } else {
      return 6;
    }
  };

  const isSurveyVisible = () => {
    if (displayOption === DisplayOption.FullScreenImage) {
      return false;
    } else {
      return true;
    }
  };

  const isImageVisible = () => {
    if (displayOption === DisplayOption.FullScreenSurvey) {
      return false;
    } else {
      return true;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSpeedDialAction = (option) => {
    setDisplayOption(option);
  };

  return (
    <div className="App">
      <header className="App-header">
        <Grid container className={classes.container}>
          <Grid item xs={getSurveyWidth()}>
            <iframe
              title="qualtrics-survey"
              className={classes.surveyIFrame}
              src={qualtricsUrlWithParams}
              style={{ display: isSurveyVisible() ? 'block' : 'none' }}
            />
          </Grid>

          <Grid item xs={getImageWidth()} style={{ display: isImageVisible() ? 'block' : 'none' }}>
            <Box className={classes.imageScrollContainer}>
              {imageUrl && isImageVisible() && <img src={imageUrl} alt="survey" className={classes.image} />}
            </Box>
          </Grid>
        </Grid>
      </header>

      {imageUrl && (
        <SpeedDial
          ariaLabel="SpeedDial example"
          className={classes.speedDial}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          direction="up">
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.onClick}
            />
          ))}
        </SpeedDial>
      )}
    </div>
  );
}

export default Survey;
