import { Box, Typography } from '@material-ui/core';
import React from 'react';

function SurveyComplete(props) {
  return (
    <Box p={5}>
      <span role="img" aria-label="tada-icon" style={{ fontSize: '72px' }}>
        🎉
      </span>
      <Typography variant="h4">Thanks for completing the survey!</Typography>
      <Typography variant="caption">
        Your response has been recorded and you can now close this window
      </Typography>
    </Box>
  );
}

export default SurveyComplete;
