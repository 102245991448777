import { Box, Typography } from '@material-ui/core';
import React from 'react';

function SurveyScreenOut(props) {
  return (
    <Box p={5}>
      <span role="img" aria-label="tada-icon" style={{ fontSize: '72px' }}>
        😅
      </span>
      <Typography variant="h4">Sorry!</Typography>
      <Typography variant="caption">
        Unfortunately, you don't match the target audience. You can now close this window.
      </Typography>
    </Box>
  );
}

export default SurveyScreenOut;
