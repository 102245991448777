import { Box, Typography } from '@material-ui/core';
import React from 'react';

function SurveyError(props) {
  return (
    <Box p={5}>
      <span role="img" aria-label="warning-icon" style={{ fontSize: '72px' }}>
        ⚠️
      </span>
      <Typography variant="h4">Whoops! It looks like something went wrong with this survey.</Typography>
      <Typography variant="caption">
        This error has been recorded and our team is investigating. You can now close this window.
      </Typography>
    </Box>
  );
}

export default SurveyError;
